
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Advertisement from "@/models/Advertisement.model";
import AdvertisementRepository from "@/api/repositories/AdvertisementRepository";

@Component
export default class ConfirmDeleteAdvertisementComponent extends Vue {
  @Prop({required: true})
  private advertisement!: Advertisement;

  /**
   * indicates if the button is loading
   */
  private isLoading = false;

  /**
   * closes the modal
   */
  private dismiss(deleted: boolean = false) {
    this.$emit('closeDialog', deleted);
  }

  /**
   * is called when the delete button is clicked, closes modal and tells the info feed view to delete the item
   * @private
   */
  private async onDeletePressed(): Promise<void> {
    try {
      this.isLoading = true;
      await AdvertisementRepository.deleteAdvertisement(this.advertisement.id);
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.ADVERTISEMENT_DELETE_SUCCESS');
      this.dismiss(true);
    } catch(e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.ADVERTISEMENT_DELETE_ERROR');
    } finally {
      this.isLoading = false;
    }
  }
}
